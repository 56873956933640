import React, { Component } from 'react';
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';

import app_store from '../images/icons/appstore.svg';
import google_play from '../images/icons/googleplay.svg';
import appgallery from '../images/icons/appgallery.svg';
import payu from '../images/icons/payu.svg';
import tpay from '../images/icons/tpaycom.svg';
import paypal from '../images/icons/paypal.svg';
import visa from '../images/icons/visa.svg';
import mastercard from '../images/icons/mastercard.svg';
import maestro from '../images/icons/maestro.svg';

import { APP_STORE_URL, GOOGLE_PLAY_URL, APP_GALLERY } from '../constants';

class Footer extends Component {
    render() {
        return (
            <footer className="d-print-none">
                <Container>
                    <Row>
                        <Col xs="12" md="4">
                            <h1>Diet&nbsp;&amp;&nbsp;Training by Ann</h1>
                            <a
                                href={APP_STORE_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={app_store} className="store" alt="App Store" />
                            </a>
                            <a
                                href={GOOGLE_PLAY_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={google_play} className="store" alt="Google Play" />
                            </a>
                            <a
                                href={APP_GALLERY}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={appgallery} className="store" alt="AppGallery" />
                            </a>
                        </Col>
                        <Col xs="12" md="4">
                            <Nav vertical>
                                <NavItem>
                                    <a
                                        className="nav-link"
                                        href={`${process.env.REACT_APP_URL}/how-to`}
                                    >
                                        Dostęp do usług i płatności
                                    </a>
                                </NavItem>
                                <NavItem>
                                    <a
                                        className="nav-link"
                                        href={`${process.env.REACT_APP_URL}/terms-of-service`}
                                    >
                                        Regulamin
                                    </a>
                                </NavItem>
                                <NavItem>
                                    <a
                                        className="nav-link"
                                        href={`${process.env.REACT_APP_URL}/privacy-policy`}
                                    >
                                        Polityka Prywatności
                                    </a>
                                </NavItem>
                                <NavItem>
                                    <a
                                        className="nav-link"
                                        href={`${process.env.REACT_APP_URL}/contact`}
                                    >
                                        Kontakt
                                    </a>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col xs="12" md="4">
                            <p>Bezpieczne płatności zapewnia</p>
                            <img src={payu} alt="PayU" />
                            <img src={tpay} alt="tPay" />
                            <img src={paypal} alt="PayPal" />
                            <br />
                            <img src={visa} alt="visa" />
                            <img src={mastercard} alt="mastercard" />
                            <img src={maestro} alt="maestro" />
                        </Col>
                    </Row>
                    <hr />
                    <p>
                        Copyright ©2016-
                        {new Date().getFullYear()} Diet&nbsp;&amp;&nbsp;Training
                        by Ann
                    </p>
                </Container>
            </footer>
        );
    }
}

export default Footer;
