import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, ModalBody } from "reactstrap";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
const ExitupComponent = props => {
	const [modal, setModal] = useState(false);

	useEffect(() => {
		document.addEventListener("mouseout", handleMouseOut);

		return () => {
			document.removeEventListener("mouseout", handleMouseOut);
		};
	}, []);

	const toggle = () => {
		if (global && global.localStorage) {
			localStorage.setItem("exitup", !modal);
		}

		setModal(!modal);
	};

	const showExitup = () => {
		setModal(true);
	};

	const handleMouseOut = event => {
		try {
			let exitup = true;

			// check local storage
			if (window && window.localStorage && window.localStorage.getItem("exitup")) {
				exitup = window.localStorage.getItem("exitup") === "true";
			}

			if (exitup) {
				const MIN_DELAY = 3000;
				const from = event.relatedTarget || event.toElement;

				if (!from || from.nodeName === "HTML") {
					if (new Date() - props.firstRenderTimestamp > MIN_DELAY) {
						showExitup();
					}
				}
			}
		} catch (error) {
			props.handleCriticalError(error);
		}
	};

	return (
        <React.Fragment>
			<Modal isOpen={modal} toggle={toggle} centered size='lg'>
				<ModalBody>
					<Container fluid={true}>
						<Row className='popup'>
							<Col xs={{ size: 12, order: 2 }} lg={{ size: 6, order: 1 }} className='px-0'>
								<StaticQuery
									query={graphql`
										query {
											mind: file(relativePath: { eq: "homepage/pop_up.jpg" }) {
												...fluidImage480
											}
										}
									`}
									render={data => <GatsbyImage image={data.mind.childImageSharp.gatsbyImageData} />}
								/>
							</Col>
							<Col
								xs={{ size: 12, order: 1 }}
								lg={{ size: 6, order: 2 }}
								className='p-5 p-lg-0 text-center d-flex flex-column justify-content-center align-items-center'>
								<h2 className='text-center mb-4 mt-4 mt-md-0'>Hej, cieszę się, że jesteś!</h2>
								<h3 className='font-weight-bold'>Lubisz nagrody?</h3>
								<p className='px-5'>
									Mam dla Ciebie coś specjalnego z daniami mocy! Wystarczy, że sprawdzisz swoją wiedzę na temat zdrowego
									odżywiania.<strong> Gotowa? Do dzieła!</strong>
								</p>
								<br />
								<p>- Anna Lewandowska</p>
								<a className='mt-3 btn btn-warm font-weight-bold' onClick={toggle} href='/quiz' data-test='exitup-button'>
									Zaczynam QUIZ
								</a>
							</Col>
						</Row>
					</Container>
					<button type='button' className='close' onClick={toggle}>
						&times;
					</button>
				</ModalBody>
			</Modal>
		</React.Fragment>
    );
};

export default ExitupComponent;
