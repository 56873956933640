import React, {ReactNode} from "react";

import Menu from "./Menu";
import Footer from "./Footer";
import Exitup from "./Exitup/ExitupComponent";

import { ParallaxProvider } from 'react-scroll-parallax';

import "../styles/main.scss";
import "../../node_modules/animate.css/animate.css";

interface Props {
    page: string;
    ctaProductName?: string
    children: ReactNode 
    showFooter?: Boolean
    showExitup?: Boolean
    showMenu?: Boolean
};


const Layout: React.FC<Props> = ({ page, ctaProductName = undefined, children, showFooter = true, showExitup = true, showMenu = false }) => (
    <div className={'page-template-' + page}>
        { showExitup ? <Exitup firstRenderTimestamp={new Date()} /> : '' }
        {showMenu ? <Menu ctaProductName={ctaProductName} /> : '' }
        <ParallaxProvider>
            <main>{children}</main>
        </ParallaxProvider>
        { !showFooter ? '' : <Footer /> }
    </div>
);  


export default Layout;
