const authorized = [
    {
        label: "Treningi",
        path: `${process.env.SITE_URL}/treningi`,
    },
    {
        label: "Wiedza",
        path: `${process.env.REACT_APP_URL}/blog/all`,
    },
    {
        label: "Blog",
        path: "https://hpba.pl",
        newWindow: true
    },
    {
        label: "Kontakt",
        path: `${process.env.REACT_APP_URL}/contact`,
    },
    {
        label: 'Przejdź do diety',
        path: `${process.env.REACT_APP_URL}/day-plan`,
    }
];

export default authorized