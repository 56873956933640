import { graphql } from "gatsby";
import trainingsIcon from "../images/icons/trainings.svg";
import calendarIcon from "../images/icons/calendar.svg";

export const APP_STORE_URL =
    "https://itunes.apple.com/pl/app/diet-by-ann-dieta-i-odchudzanie-anna-lewandowska/id1142970408";
export const GOOGLE_PLAY_URL =
    "https://play.google.com/store/apps/details?id=com.apzumi.mobile.dietlabs.dietByAnn&hl=pl";
export const APP_GALLERY = 'https://appgallery7.huawei.com/#/app/C100541897';

export const TRAINING_PROGRAMS = 70;
export const TRAINING_PROGRAMS_ICON = trainingsIcon;
export const CALENDAR_ICON = calendarIcon;
export const TRAINING_DAYS = 4050;
export const DIET_COUNT = 4;
export const USER_COUNT = '3 300 000';
export const DVD_COUNT_GREATER_THAN = 1450;

export const fluidImage1920 = graphql`fragment fluidImage1920 on File {
  childImageSharp {
    gatsbyImageData(layout: FULL_WIDTH, quality: 80)
  }
}
`;

export const fluidImage960 = graphql`fragment fluidImage960 on File {
  childImageSharp {
    gatsbyImageData(width: 960, layout: CONSTRAINED, quality: 80)
  }
}
`;

export const fluidImage480 = graphql`fragment fluidImage480 on File {
  childImageSharp {
    gatsbyImageData(width: 480, quality: 80, layout: CONSTRAINED)
  }
}
`;

export const fixedImage176 = graphql`fragment fixedImage176 on File {
  childImageSharp {
    gatsbyImageData(width: 176, layout: FIXED, quality: 80)
  }
}
`;

export const fixedImage100 = graphql`fragment fixedImage100 on File {
  childImageSharp {
    gatsbyImageData(width: 100, layout: FIXED, quality: 80)
  }
}
`;

export const fixedImage62 = graphql`fragment fixedImage62 on File {
  childImageSharp {
    gatsbyImageData(width: 62, layout: FIXED, quality: 80)
  }
}
`;

export const fluidBackground = graphql`fragment fluidBackground on File {
  childImageSharp {
    fluid(quality: 90, maxWidth: 1920) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
}
`;
